import React, { useState, useEffect, useRef } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import SelectGautra from '../family-tree/select-gautra';
import SelectVillage from '../family-tree/select-village';
import { Radio } from '@paljs/ui/Radio';
import { Checkbox } from '@paljs/ui/Checkbox';
import { koot_falan } from '../../helpers/utils';
import { createVolunteer } from '../../actions/createVolunteerAction';
import EditDate from '../../components/date/editDate';
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import SelectName from '../family-tree/select-name';
import { searchUser } from '../../actions/searchAction';
import Modal from '../../Utilities/Modal';
import SearchResultTable from '../search/search_result_table';
import LoadingSpinner from '../loader';

const Volunteer = () => {
  const [name, setName] = useState('');
  const [gautraObj, setGautraObj] = useState({});
  const [subcaste, setSubcaste] = useState('');
  const [village, setVillage] = useState('');
  const [gender, setGender] = useState('male');
  const [alive, setAlive] = useState(true);
  const [mobile, setMobile] = useState('');
  const [pincode, setPincode] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [buttonText, setButtonText] = useState('ADD VOLUNTEER');
  const dateOfBirthRef = useRef();
  const [errMsg, setErrMsg] = useState('');
  const [showSearchResultTable, setShowSearchResultTable] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const toastData = {
    position: 'topEnd',
    status: 'Success',
    duration: 2000,
    hasIcon: true,
    destroyByClick: true,
    preventDuplicates: false,
  };
  const toastrRef = useRef<ToastrRef>(null);

  const resetForm = () => {
    setDateOfBirth('');
    setEmail('');
    setMobile('');
    setAlive(true);
    setGender('male');
    setVillage('');
    setSubcaste('');
    setName('');
    setGautraObj({});
    setPincode('');
    dateOfBirthRef?.current?.clearData();
  };
  let userSearchParams = {};
  if (name) {
    userSearchParams.name = name.trim();
  }
  if (gautraObj.gautra) {
    userSearchParams.gautra = gautraObj?.gautra?.trim();
  }
  if (gautraObj.subcaste) {
    userSearchParams.subcaste = gautraObj?.subcaste?.trim();
  }
  if (village) {
    userSearchParams.village = village.trim();
  }
  const onSubmit = async () => {
    setShowSearchResultTable(false);
    setLoading(true);
    const kootFalanName = koot_falan(name);
    const data = {
      name,
      gautra: gautraObj?.gautra,
      subcaste: gautraObj?.subcaste ?? '',
      village,
      gender,
      alive: alive ? 'alive' : 'dead',
      mobile,
      pincode,
      email,
      born: dateOfBirth,
      koot_falan: kootFalanName,
    };

    setButtonText('Adding...');
    const response = await createVolunteer(data);
    console.log('created volunteer', response);
    if (response?.status) {
      const toastTitle = 'Success';
      const toastMessage = 'volunteer added successfully';
      toastrRef.current?.add(toastMessage, toastTitle, { ...toastData });
      resetForm();
    } else {
      const toastTitle = 'Error';
      const toastMessage = 'Error Occurred';
      toastrRef.current?.add(toastMessage, toastTitle, { ...toastData, status: 'Danger' });
      resetForm();
    }
    setLoading(false);
    setButtonText('ADD VOLUNTEER');
  };
  const handleAddVolunteer = async () => {
    if (name && gautraObj && village) setErrMsg('');
    else if (!name || !gautraObj || !village) {
      let missingFields = [];
      if (!name) {
        missingFields.push('Name');
      }
      if (!gautraObj) {
        missingFields.push('Gautra');
      }
      if (!village) {
        missingFields.push('Village');
      }
      let errMsg = `Please fill out all required fields: (${missingFields.join(', ')})`;
      setErrMsg(errMsg);
      console.log('ErrMsg ', errMsg);
      return;
    }

    const searchParams = {
      name: name.trim(),
      gautra: gautraObj?.gautra,
      subcaste: gautraObj?.subcaste ?? '',
      gender: gender.trim(),
      koot_falan: name ? koot_falan(name).trim() : '',
      village_name: village.trim(),
      new_node_mobile: mobile.trim(),
    };

    setLoading(true);
    const search_response = await searchUser(searchParams);
    setSearchResults(search_response?.search_results);
    console.log('checking response in add relation', search_response);
    setLoading(false);
    if (search_response?.mobile_search_results?.length) {
      const toastTitle = 'Error';
      const toastMessage = `Mobile No. already present`;
      toastrRef.current?.add(toastMessage, toastTitle, { ...toastData, status: 'Danger' });
    } else if (search_response?.search_results?.length) setShowSearchResultTable(true);
    else onSubmit();
  };

  return (
    <>
      {showSearchResultTable && (
        <Modal toggle={() => setShowSearchResultTable(false)}>
          <Card>
            <CardBody>
              <SearchResultTable
                searchResults={searchResults}
                onClose={onSubmit}
                noneOfThese={true}
                selectButton={false}
                searchParams={userSearchParams}
              />
            </CardBody>
          </Card>
        </Modal>
      )}
      <Toastr ref={toastrRef} />
      <Card status="Info">
        <CardHeader>Create Volunteer</CardHeader>
        <CardBody>
          {loading && <LoadingSpinner message="Loading..." />}
          {errMsg && <div style={{ color: 'red', marginTop: '10px' }}>{errMsg}</div>}
          <Row>
            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: '10px' }}>Gender:</span> {/* Gender Label */}
              <Radio
                name="radio"
                onChange={(val) => setGender(val)}
                options={[
                  {
                    value: 'male',
                    label: 'Male',
                    status: 'Info',
                    checked: true,
                  },
                  {
                    value: 'female',
                    label: 'Female',
                    status: 'Info',
                  },
                ]}
                style={{ marginLeft: '10px' }} // Apply margin to Radio component
              />
            </Col>

            <Col>
              <>
                Name<span style={{ color: 'red' }}>*</span>
              </>

              {/* <InputGroup fullWidth>
                                <input
                                    value={name}
                                    placeholder="name"
                                    onChange={(event) => { setName(event.target.value) }}
                                    onBlur={(event)=>{setName(event.target.value.trim())}}
                                />
                            </InputGroup> */}
              <SelectName setName={setName} gender={gender} name={name} />
            </Col>

            <Col>
              <>
                Gautra<span style={{ color: 'red' }}>*</span>
              </>
              <SelectGautra setGautraObj={setGautraObj} gautraObj={gautraObj} disabled={false} type="creatable" />
            </Col>

            <Col>
              <>
                Village<span style={{ color: 'red' }}>*</span>
              </>
              <SelectVillage setVillage={setVillage} village={village} />
            </Col>

            <Col>
              <>Pincode:</>
              <InputGroup>
                <input
                  value={pincode}
                  placeholder="Pincode"
                  type="number"
                  id="pin_code"
                  name="pin_code"
                  onChange={(event) => {
                    setPincode(event.target.value.trim());
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <>Email:</>
              <InputGroup>
                <input
                  value={email}
                  placeholder="Email"
                  onChange={(event) => {
                    setEmail(event.target.value.trim());
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <>Mobile:</>
              <InputGroup>
                <input
                  value={mobile}
                  placeholder="Mobile"
                  type="tel"
                  id="tel"
                  name="tel"
                  onChange={(event) => {
                    setMobile(event.target.value.trim());
                  }}
                />
              </InputGroup>
            </Col>

            <Col>
              <>Date ofBirth :</>
              <EditDate defaultDate={dateOfBirth} onChange={setDateOfBirth} ref={dateOfBirthRef} />
            </Col>

            <Col>
              <>Alive? : </>
              <br />
              <Checkbox
                checked={alive}
                onChange={(value) => {
                  setAlive(value);
                }}
              >
                Alive
              </Checkbox>
            </Col>
          </Row>
        </CardBody>
        <CardFooter>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              status="Success"
              type="button"
              shape="SemiRound"
              style={{ margin: '5px', display: 'block' }}
              onClick={handleAddVolunteer}
              disabled={buttonDisable}
            >
              {buttonText}
            </Button>
            <Button
              status="Danger"
              type="button"
              shape="SemiRound"
              style={{ margin: '5px', display: 'block' }}
              onClick={resetForm}
            >
              Reset
            </Button>
          </Row>
        </CardFooter>
      </Card>
    </>
  );
};

export default Volunteer;
