// import { showAlert } from "../helper/flashHelper";
import { SUCCESS, ERROR, API_HOST } from '../constant/string.js';
import { getApiWrapper, postApiWrapper } from "./token-wrapper-function";


// Login - get user token
export const searchUser = async (data) => {
  const url = `/api/tasks/search.php`;
  const result = await postApiWrapper(url, data);
  return result;
}

export const updateUser = async (row, name, value) => {
  const url = `/api/users/basic/edit`;
  const data = {
    graph_node_id: row.uid,
  };
  data[name] = value;
  const result = await postApiWrapper(url, data);
  return result;
};

export const searchUserByMobile = async (data) => {
  const url = `/api/search_by_mobile/${data.mobile}`;
  const result = await getApiWrapper(url);
  return result;
}
